<template>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">

    <b-tabs v-model="tabIndex">
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>  {{ $t('Plant') }}
</span>
        </template>

        <div>
          <!-- Media -->
          <b-media class="mb-2">
            <template #aside>
              <cropper
                  id="profile_image"
                  ref="cropper"
                  :src="url"
                  class="cropper h-24 w-24"
                  v-if="showCropper"
                  :stencil-props="{
		aspectRatio: 10/10
	}"
              />
              <img v-else :src="url" class="mr-8 rounded h-24 w-24 profile-pic"/>
            </template>
            <h4 class="mb-1">
              {{ plant.manufacturer + ' ' + plant.model + ' ' + plant.plant_id }}
            </h4>
            <div class="d-flex flex-wrap">

              <input
                  type="file"
                  ref="refInputEl"
                  class="hidden"
                  accept="image/*"
                  name="profile_image"
                  id="profile_image"
                  @change="selectedFile"
              />

              <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                  v-if="!showCropper"
                  :disabled="allEditDisabled"
              >

                <span class="d-none d-sm-inline">{{ $t('Upload image') }}</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  variant="primary"
                  @click="cropImage()"
                  v-else
              >

                <span class="d-none d-sm-inline">{{ $t('Crop') }}</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  :disabled="allEditDisabled"

                  @click="deleteImage()"
              >
                <span class="d-none d-sm-inline">{{ $t('Delete') }}</span>
                <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"

                />
              </b-button>
            </div>
          </b-media>

          <!-- User Info: Input Fields -->
          <validation-observer
              ref="validationRules"
              tag="form"
          >
          <b-form>

            <b-row >

              <!-- Field: Manufacturer -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-for="manufacturer"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="manufacturer"
                      rules="required"
                      :custom-messages="customMessages"
                      :state="errors.length > 0 ? false:null"

                  >
                    <label v-html="getRequiredLabel('Manufacturer')"></label>

                    <b-form-input
                      :disabled="allEditDisabled"
                      id="manufacturer"
                      v-model="plant.manufacturer"
                  />                      <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Model -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-for="model"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="model"
                      rules="required"
                      :custom-messages="customMessages"
                  >
                    <label v-html="getRequiredLabel('Model')"></label>

                    <b-form-input
                      :disabled="allEditDisabled"
                      id="model"
                      v-model="plant.model"
                      :state="errors.length > 0 ? false:null"

                  />                      <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Plant ID -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group

                    label-for="plant_id"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Plant ID"
                      rules="required"
                      :custom-messages="customMessages"

                  >
                    <label v-html="getRequiredLabel('Plant ID')"></label>

                    <b-form-input
                      id="plant_id"
                      :disabled="allEditDisabled"
                      v-model="plant.plant_id"
                      :state="errors.length > 0 ? false:null"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

              <b-row>
                <!-- Field: Description -->
                <b-col
                    cols="12"
                    md="12"
                >
                  <b-form-group
                      label-for="description"
                  >


                    <validation-provider
                        #default="{ errors }"
                        name="description"
                        rules="required"
                        :custom-messages="customMessages"
                    >
                      <label v-html="getRequiredLabel('Description')"></label>

                      <b-form-input
                          id="description"
                          :disabled="allEditDisabled"
                          v-model="plant.description"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>


              </b-row>
            <b-row>
              <!-- Field: Note -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Note')"
                    label-for="note"
                >
                  <b-form-textarea
                      rows="3"
                      id="note"
                      name="note"
                      v-model="plant.note"
                      :disabled="allEditDisabled"
                  />
                </b-form-group>
              </b-col>



            </b-row>
          </b-form>
          </validation-observer>


          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="handleSubmit()"
              :disabled="allEditDisabled"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="back($event)"
          >
            {{ $t('Cancel') }}


          </b-button>
        </div>


      </b-tab>
      <b-tab ref='documents_tab' :disabled="!documents_enabled">
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span> {{ $t('Documents') }}</span>
        </template>

        <plant-documents-list-component/>
      </b-tab>

    </b-tabs>
  </b-card-actions>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,

  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EquipmentService from "@/basic/equipmentService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import PlantDocumentsListComponent from "@/views/table/vue-good-table/PlantDocumentsListComponent";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ability from "@/libs/acl/ability";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    PlantDocumentsListComponent,
    BFormFile,
    Cropper,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      plant: {
        manufacturer: "",
        model: "",
        plant_id: "",
        model_id: "",
        photo: "",
        note: "",

      },
      customMessages: {
        required: i18n.t('This field is required'),
      },
      errors:[],
      company_users: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: !ability.can("manage", "Basic HR"),
      department_new_id: 1,
      role_new_id: 1,
      plant_roles: [{id: 1, name: ""}],
      plant_departments: [{id: 1, name: ""}],
      dateDefault: null,
      assignment_options: ['a', 'b', 'c'],
      assignment_selected: [],
      protected_category: false,
      documents_enabled: false,
      medical_enabled: false,
      avatar_file: null,
      filedata: null,
      images: null,
      url: require("@/assets/images/avatars/plant.png"),
      variant: "light",
      opacity: 0.85,
      blur: "2px",
      crop: {
        src: "",
        type: "image/jpg",
      },
      file_name: "",
      showCropper: false,
      tab: this.$route.params.tab ? this.$route.params.tab : "main",
      tabIndex: 1,
    }
  },
  computed: {

  },
  methods: {
    refresh() {

      console.log('tab', this.tab)
      switch (this.tab) {
        case "main":
          this.tabIndex = 1;
          break;
        case "plant_documents":
          this.tabIndex = 2;
          break;
        default:
          this.tabIndex = 1;
          break;
      }

      console.log('tabIndex', this.tabIndex)

      const equipmentService = new EquipmentService(useJwt)

      let plant_id = this.$route.params.id;
      this.$refs["card"].showLoading = true


      let company_id = store.state.userCompany.id;


      equipmentService.plant(plant_id, company_id).then(response => {


        if (response.data.data[0]) {
          console.log("plant", response.data.data[0])
          this.plant = response.data.data[0]
          this.url = this.plant.photo
          this.documents_enabled = true;
        } else {
          console.log('plant not found')
        }

        console.log('tabIndex s', this.tabIndex)

        console.log('data', response.data.data)
        console.log('plant_roles', this.plant_roles)
        this.$refs["card"].showLoading = false

      })


    },
    async back(e) {
      this.$router.push({name: 'plants'})

    },
    getRequiredLabel(field) {
      return '<span class="text-danger">*</span> ' + i18n.t(field);
    },
    selectedFile(event) {
      this.success = "";
      this.error = "";
      var filePath = event.target.files[0].name;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(filePath)) {
        this.error = this.$t("FileUploadError");
        this.$toasted.show(this.error, {
          className: "top-alert",
          position: "top-center",
          type: "error",
          duration: 2000,
        });
        $("#profile_image").val("");
      } else {
        this.images = event.target.files[0];
        this.file_name = this.images.name;
        this.url = URL.createObjectURL(this.images);
        this.crop.src = URL.createObjectURL(this.images);
        this.showCropper = true;
      }
    },

    handleUpload() {

      let filevalue = this.avatar_file;
      console.log('handleUpload filevalue', filevalue)

      var filePath = filevalue.name;
      console.log('handleUpload filePath', filevalue.name)


      let reader = new FileReader();

      reader.onload = (e) => {
        console.log('readAsDataURL', e.target.result);
        this.filedata = e.target.result
        const data_upload = {
          filedata: this.filedata,
          filename: filePath
        };
        console.log("UPLOAD 2", filedata)

        this.plant.photo = filedata;

      };

      reader.readAsDataURL(this.avatar_file);


    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      let obj = this;
      this.url = result.canvas.toDataURL(this.crop.type);
      console.log('url', this.url)
      this.urltoFile(
          result.canvas.toDataURL(this.crop.type),
          this.file_name,
          "image/*"
      ).then(function (file) {
        console.log("rahul--", file);
        obj.images = file;

      });
      this.images = obj.images;
      this.showCropper = false;

    },

    deleteImage() {
      console.log('deleteImage')
      this.images = "";
      this.showCropper = false;
      this.url = require("@/assets/images/avatars/avatar.png");


    },

    urltoFile(url, filename, mimeType) {
      return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, {type: mimeType});
          });
    },


    async handleSubmit(e) {

      this.$refs.validationRules.validate().then(success => {
        if (success) {
          console.log("VALIDATION OK")

          const equipmentService = new EquipmentService(useJwt)
          console.log("inputImageRenderer", this.inputImageRenderer)
          this.submitted = true;
          //const valid = await this.$validator.validate();
          const valid = true;

          if (!valid) {
            this.$vs.notify({
              title: this.$t("create_admin.FailedToCreateAdmin"),
              text: this.$t("create_admin.ConfirmPasswordFailed"),
              color: "danger",
              position: "top-center"
            });
            this.submitted = false;
            return false;
          }

          this.$refs["card"].showLoading = true
          this.plant.protected_category = this.protected_category
          this.plant.photo = this.url
          const data = {
            plant: this.plant,
            company: this.userCompany,
            photo_file_name: this.file_name,
          };


          try {
            console.log('data', data)
            let plant_id = this.$route.params.id;

            equipmentService.update_plant(plant_id, data).then(response => {
              console.log("response", response)
//          this.refresh();
              this.$refs["card"].showLoading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: i18n.t('Plant'),
                  icon: 'AlertIcon',
                  variant: 'success',
                  text: i18n.t('Plant saved with success'),
                },
              })
              this.$router.push({name: 'plants'})
            })

            const response = {status: 200, data: {id: 1}}

            this.submitted = true;

          } catch (error) {

          }
        } else {
          console.log("VALIDATION KO")

          this.submitted = false;

        }

      })
    },

  },
  mounted() {
    this.refresh()
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
