var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"card",attrs:{"title":"","action-refresh":""},on:{"refresh":_vm.refresh}},[_c('b-tabs',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"HomeIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('Plant'))+" ")])]},proxy:true}])},[_c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [(_vm.showCropper)?_c('cropper',{ref:"cropper",staticClass:"cropper h-24 w-24",attrs:{"id":"profile_image","src":_vm.url,"stencil-props":{
		aspectRatio: 10/10
	}}}):_c('img',{staticClass:"mr-8 rounded h-24 w-24 profile-pic",attrs:{"src":_vm.url}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.plant.manufacturer + ' ' + _vm.plant.model + ' ' + _vm.plant.plant_id)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('input',{ref:"refInputEl",staticClass:"hidden",attrs:{"type":"file","accept":"image/*","name":"profile_image","id":"profile_image"},on:{"change":_vm.selectedFile}}),(!_vm.showCropper)?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.allEditDisabled},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t('Upload image')))]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.cropImage()}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t('Crop')))]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary","disabled":_vm.allEditDisabled},on:{"click":function($event){return _vm.deleteImage()}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t('Delete')))]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1)]),_c('validation-observer',{ref:"validationRules",attrs:{"tag":"form"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"manufacturer"}},[_c('validation-provider',{attrs:{"name":"manufacturer","rules":"required","custom-messages":_vm.customMessages,"state":_vm.errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var errors = ref.errors;
return [_c('label',{domProps:{"innerHTML":_vm._s(_vm.getRequiredLabel('Manufacturer'))}}),_c('b-form-input',{attrs:{"disabled":_vm.allEditDisabled,"id":"manufacturer"},model:{value:(_vm.plant.manufacturer),callback:function ($$v) {_vm.$set(_vm.plant, "manufacturer", $$v)},expression:"plant.manufacturer"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"model"}},[_c('validation-provider',{attrs:{"name":"model","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var errors = ref.errors;
return [_c('label',{domProps:{"innerHTML":_vm._s(_vm.getRequiredLabel('Model'))}}),_c('b-form-input',{attrs:{"disabled":_vm.allEditDisabled,"id":"model","state":errors.length > 0 ? false:null},model:{value:(_vm.plant.model),callback:function ($$v) {_vm.$set(_vm.plant, "model", $$v)},expression:"plant.model"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"plant_id"}},[_c('validation-provider',{attrs:{"name":"Plant ID","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var errors = ref.errors;
return [_c('label',{domProps:{"innerHTML":_vm._s(_vm.getRequiredLabel('Plant ID'))}}),_c('b-form-input',{attrs:{"id":"plant_id","disabled":_vm.allEditDisabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.plant.plant_id),callback:function ($$v) {_vm.$set(_vm.plant, "plant_id", $$v)},expression:"plant.plant_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var errors = ref.errors;
return [_c('label',{domProps:{"innerHTML":_vm._s(_vm.getRequiredLabel('Description'))}}),_c('b-form-input',{attrs:{"id":"description","disabled":_vm.allEditDisabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.plant.description),callback:function ($$v) {_vm.$set(_vm.plant, "description", $$v)},expression:"plant.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Note'),"label-for":"note"}},[_c('b-form-textarea',{attrs:{"rows":"3","id":"note","name":"note","disabled":_vm.allEditDisabled},model:{value:(_vm.plant.note),callback:function ($$v) {_vm.$set(_vm.plant, "note", $$v)},expression:"plant.note"}})],1)],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":_vm.allEditDisabled},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.back($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)]),_c('b-tab',{ref:"documents_tab",attrs:{"disabled":!_vm.documents_enabled},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"BookIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('Documents')))])]},proxy:true}])},[_c('plant-documents-list-component')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }